<template>
    <div>
        <v-toolbar dense color="primary" flat dark>
            <v-toolbar-title>Movimientos por área</v-toolbar-title>
            <v-spacer/>
            <v-btn @click="busca">
                Buscar
            </v-btn>
        </v-toolbar>
        <v-sheet class="pa-2" elevation="2">
            <v-row no-gutters>
                <v-col md=3 cols="12">
                    <v-date-picker
                        v-model="fechaPicker"
                        range
                        full-width
                        no-title
                        scrollable
                        :max="estaFecha"
                        locale="es-mx"
                    />
                </v-col>
                <v-col md=9 cols="12">

                    <v-data-table
                        class="mt-2"
                        :headers="headers"
                        :items="movimientosTodos"
                        hide-default-footer
                        item-key="id"
                        :loading="loadingDatos"
                        dense
                        :server-items-length='movimientosTotal'
                        disable-pagination
                    />


                </v-col>
            </v-row>
        </v-sheet>



    </div>
</template>

<script>
export default {
    mounted(){
        //this.db.tipo = 'i'
        //this.areaServicio = 'a'
        this.fechaPicker = [
            this.moment(new Date()).format("YYYY-MM-DD"),
            this.moment(new Date()).format("YYYY-MM-DD"),
        ]
    },
    data:()=>({
        loadingDatos:false,
        db:{},
        fechaPicker:[],
        areaServicio:'',

        areas:[],
        servicios:[],
        tiposAlta:[],
        edades:[
            {value:0, text:'<1a'},
            {value:1, text:'1a'},
            {value:2, text:'2a'},
            {value:3, text:'3a'},
            {value:4, text:'4a'},
            {value:5, text:'5a'},
            {value:6, text:'6a'},
            {value:7, text:'7a'},
            {value:8, text:'8a'},
            {value:9, text:'9a'},
            {value:10, text:'10a'},
            {value:11, text:'11a'},
            {value:12, text:'12a'},
            {value:13, text:'13a'},
            {value:14, text:'14a'},
            {value:15, text:'15a'},
            {value:16, text:'16a'},
            {value:17, text:'17a'},
            {value:18, text:'>18a'},
        ],

        headers:[
            { text: 'Área', value: 'area'},
            { text: 'Dias total', value: 'diasTotal', align:'center'},
            { text: 'Movimientos', value: 'movimientos', align:'center'},
            { text: 'Promedio días', value: 'promedioDia', align:'center'},
        ],
        movimientosTodos:[],
        movimientosTotal:0,
    }),
    methods:{
        async busca(){
            this.loadingDatos = true
            try{
                this.loadingDatos = false
                const req = await this.$http({
                    method:"POST",
                    url:"/reportes/reporteMovimientosArea",
                    params:this.db,
                })
                console.log(req.data)
                this.movimientosTodos=req.data.root
                this.movimientosTotal=req.data.total
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingDatos = false
            }
        },
        colorArea(id){
            let text = 'black'
            if ([8,7,6,5,14,13].includes(id)){
                text = 'green'
            }
            if ([1,2,3,9,10,11].includes(id)){
                text = 'orange'
            }

            return (text)
        },
        async getAreas(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/catalogos/areas'
                })
                this.areas = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getServicios(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/catalogos/servicios'
                })
                this.servicios = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getTiposAlta(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/catalogos/tiposaltas'
                })
                this.tiposAlta = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        },
    },
    watch:{
        fechaPicker(){
            let far=[]
            // this.fechaArr = []
            if(this.fechaPicker.length > 0){
                this.fechaPicker.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00'))
                })

                this.db.fechaDe = this.moment(new Date(Math.min.apply(null,far))).format("DD-MM-YYYY")
                this.db.fechaA = this.moment(new Date(Math.max.apply(null,far))).format("DD-MM-YYYY")
            }
        },
    },
}
</script>

<style>

</style>